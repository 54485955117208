<script>
import { productFruits } from 'product-fruits'
import IDENTITY from '../queries/Identity.gql'

export default {
  data: () => ({
    identity: null,
    isInitialized: false
  }),

  watch: {
    identity (identity) {
      if (identity && !this.isInitialized) {
        this.init()
        this.isInitialized = true
      }
    }
  },

  methods: {
    init () {
      const { user, company } = this.identity
      productFruits.init(
        process.env.VUE_APP_PRODUCT_FRUITS_WORKSPACE_CODE,
        this.$i18n.locale.slice(0, 2),
        {
          username: user.id,
          firstname: user.firstname,
          lastname: user.lastname,
          signUpAt: user.createdAt,
          role: user.role?.slug,
          props: {
            gender: user.gender,
            countryCode: company.country?.toUpperCase(),
            companySlug: company.slug,
            companyName: company.name,
            productType: company.productType
          }
        },
        {
          customNavigation: {
            use: true,
            navigate: (location) => {
              if (location.startsWith('http')) {
                window.open(location, '_self')
              } else {
                this.$router.push(location)
              }
            },
            onGet () {
              return window.location.href
            }
          }
        }
      )
    },

    useProductFruits () {
      return Boolean(process.env.VUE_APP_PRODUCT_FRUITS_WORKSPACE_CODE)
    }
  },

  apollo: {
    identity: {
      query: IDENTITY,
      skip () {
        return !this.useProductFruits() || this.$auth.loading || !this.$auth.isAuthenticated || !this.$auth.user.companyId
      }
    }
  },

  render () {
    return null
  }
}
</script>
