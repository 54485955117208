<template>
  <div class="navbar">
    <v-app-bar
      v-if="$auth.isAuthenticated && $vuetify.breakpoint.mdAndDown"
      color="white"
      clipped-left
      app
    >
      <template v-if="$vuetify.breakpoint.mdAndDown">
        <v-img
          alt="Firmen Logo"
          class="shrink"
          :src="require('@/../public/img/logo.png')"
          width="140"
          min-height="16"
          contain
          @click="goHome"
        />

        <v-spacer />

        <v-app-bar-nav-icon
          v-if="$auth.isAuthenticated"
          @click.stop="showDrawer = !showDrawer"
        />
      </template>
    </v-app-bar>

    <v-navigation-drawer
      v-if="$auth.isAuthenticated"
      v-model="showDrawer"
      class="px-2 pb-3"
      clipped
      app
      mini-variant-width="60"
      :width="270"
      :right="$vuetify.breakpoint.mdAndDown"
      :expand-on-hover="isDrawerCollapsible"
      @transitionend="isDrawerOpen = !isDrawerOpen"
    >
      <!-- Company Logo -->
      <template v-slot:prepend>
        <v-list-item
          id="company-logo"
          to="/dashboard"
          exact
          two-line
          link
          class="px-2"
          @click="trackClick('Logo')"
        >
          <v-list-item-avatar tile>
            <v-img
              :alt="$t('navigation.logo.alt-texts.0')"
              :src="require('@/../public/img/logo-icon.png')"
              @click="goHome"
            />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>
              <v-img
                :alt="$t('navigation.logo.alt-texts.1')"
                :src="require('@/../public/img/logo-name.png')"
                @click="goHome"
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <!-- Primary Navigation -->
      <v-list class="mt-md-8">
        <v-list-item
          v-for="item in navItems"
          :key="item.to"
          :to="item.to"
          class="rounded-lg"
          active-class="rounded-lg grey lighten-3 font-weight-bold"
          color="primary"
          @click="trackClick(item.trackingLabel)"
        >
          <v-list-item-icon>
            <v-badge
              v-if="item.badge && item.badge.condition({showRegeneration})"
              bordered
              bottom
              :color="item.badge.color"
              dot
              offset-x="10"
              offset-y="10"
            >
              <v-icon>{{ item.icon }}</v-icon>
            </v-badge>
            <v-badge
              v-else-if="item.isNew && showIsNew"
              content="NEW"
              color="orange"
              offset-x="10"
              offset-y="10"
            >
              <v-icon>{{ item.icon }}</v-icon>
            </v-badge>
            <v-icon v-else>
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>
              {{ $t(item.label) }}
              <v-badge
                v-if="item.isBeta"
                content="BETA"
                color="orange"
                class="ml-2"
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider />

      <!-- Secondary Navigation -->
      <v-list
        dense
      >
        <v-list-item
          v-for="item in navItemsBottimmo"
          :id="item.trackingLabel.toLowerCase()"
          :key="item.to"
          :to="item.to"
          :href="item.href"
          :target="item.target"
          :rel="item.rel"
          @click="trackClick(item.trackingLabel)"
        >
          <v-list-item-icon>
            <span
              v-if="item.trackingLabel === 'News' && unreadNewsCount > 0"
              class="unread-messages-count"
            >
              {{ unreadNewsCount }}
            </span>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-badge
            v-if="item.badge && item.badge.condition({ showRegeneration })"
            :color="item.badge.color"
            :icon="item.badge.icon"
          >
            <v-list-item-content>
              <v-list-item-title>{{ $t(item.label) }}</v-list-item-title>
            </v-list-item-content>
          </v-badge>
          <v-list-item-content v-else>
            <v-list-item-title>{{ $t(item.label) }}</v-list-item-title>
          </v-list-item-content>

          <v-list-item-action v-if="item.href">
            <v-icon
              color="grey lighten-1"
              size="14"
            >
              mdi-open-in-new
            </v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>

      <!-- User Area -->
      <template v-slot:append>
        <v-menu
          v-model="isUserMenuOpen"
          style="z-index:11"
          offset-y
          top
        >
          <template v-slot:activator="{ on, attrs }">
            <v-list-item
              dense
              v-bind="attrs"
              class="rounded-lg user-item"
              :class="{'grey lighten-3': isDrawerOpen}"
              v-on="on"
              @click="trackClick('User Menu')"
            >
              <v-list-item-avatar>
                <PackageLogo
                  :current-package="currentPackage"
                  :show-label="false"
                  class="mr-6"
                  :style="{'marginTop':'-38px', marginLeft: '25px'}"
                  size="medium"
                />
              </v-list-item-avatar>

              <v-list-item-content>
                <div class="d-flex justify-space-between">
                  <div>
                    <span
                      v-if="$auth.user.given_name && $auth.user.family_name"
                      class="text-caption font-weight-bold"
                    > {{ $auth.user.given_name + ' ' + $auth.user.family_name }}</span>
                    <span
                      v-else
                      class="text-caption font-weight-bold d-inline-block text-truncate"
                      style="max-width: 140px"
                    > {{ $auth.user.email }}</span>
                    <br><span
                      v-if="company"
                      class="text-caption font-weight-bold grey--text d-inline-block text-truncate"
                      style="max-width: 140px"
                    > {{ company.name }}</span>
                  </div>
                  <v-icon
                    small
                    class="pl-2"
                  >
                    {{ isUserMenuOpen ? "mdi-arrow-down" : "mdi-arrow-up" }}
                  </v-icon>
                </div>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list>
            <v-list-item
              to="/settings/profile"
              dense
              exact
              link
              @click="trackClick('Profile')"
            >
              <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ $t('navigation.labels.profile') }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              dense
              @click="logout"
            >
              <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
              <v-list-item-title>{{ $t('navigation.labels.logout') }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </v-navigation-drawer>
  </div>
</template>

<script>
import COMPANY_BASE from '../../queries/CompanyBase.gql'
import PENDING_CHANGES_FOR_REGENERATION from '../../queries/PendingChangesForRegeneration.gql'
import featureNames from '@/lib/featureNames'
import brandingMixin from '@/mixins/branding'
import featureMixin from '@/mixins/feature'
import PackageLogo from '@/components/PackageLogo.vue'
import { productFruits } from 'product-fruits'

const BASIC_NAVIGATION = [
  {
    label: 'navigation.labels.dashboard',
    trackingLabel: 'Dashboard',
    to: '/dashboard',
    icon: 'mdi-view-dashboard-outline'
  },
  {
    label: 'navigation.labels.topics',
    trackingLabel: 'Topics',
    to: '/topics',
    icon: 'mdi-database',
    feature: featureNames.TOPIC,
    checkConfigIsActive: (config) => config?.isUserManageable ?? false,
    isNew: true
  },
  {
    label: 'navigation.labels.leads',
    trackingLabel: 'Leads',
    to: '/leads',
    icon: 'mdi-account-multiple-outline',
    feature: featureNames.LEAD,
    permission: 'lead:read'
  },
  {
    label: 'navigation.labels.landingpages',
    trackingLabel: 'Landingpages',
    to: '/landingpages',
    icon: 'mdi-button-pointer',
    feature: featureNames.LANDINGPAGE,
    permission: 'material:read'
  },
  {
    label: 'navigation.labels.content-creator',
    trackingLabel: 'Content Creator',
    to: '/content-creator',
    icon: 'mdi-brain',
    feature: featureNames.CONTENT_CREATOR,
    permission: 'ai:read'
  },
  {
    label: 'navigation.labels.valuation',
    trackingLabel: 'Valuation',
    to: '/valuation',
    icon: 'mdi-file-chart-check-outline',
    feature: featureNames.VALUATION,
    checkConfigIsActive: (config) => config.isInAppValuationEnabled,
    permission: 'valuation:read'
  },
  {
    label: 'navigation.labels.partner',
    trackingLabel: 'Partner',
    to: '/partner',
    icon: 'mdi-handshake-outline',
    feature: featureNames.PARTNER,
    permission: 'partner:read'
  },
  {
    label: 'navigation.labels.performance',
    trackingLabel: 'Performance Report',
    to: '/performance',
    icon: 'mdi-gauge',
    feature: featureNames.PERFORMANCE_MARKETING,
    permission: 'performance:read'
  },
  {
    label: 'navigation.labels.statistic-dashboard',
    trackingLabel: 'Statistic Dashboard',
    to: '/statistic-dashboard',
    icon: 'mdi-poll',
    feature: featureNames.STATISTIC_DASHBOARD,
    permission: 'lead:read'
  },
  {
    label: 'navigation.labels.settings',
    trackingLabel: 'Settings',
    to: '/settings',
    icon: 'mdi-cog-outline',
    permission: 'settings:read'
  }
]

const ONBOARING_NAVIGATION = [{
  label: 'navigation.labels.onboarding',
  trackingLabel: 'Onboarding',
  to: '/onboarding',
  icon: 'mdi-view-dashboard-outline'
}]

export default {
  components: { PackageLogo },
  mixins: [brandingMixin, featureMixin],
  inject: ['role'],
  props: {
    isDrawerCollapsible: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      // Will be initially set to boolean by the drawer component
      // depending on the viewport size
      showDrawer: null,
      isDrawerOpen: !this.isDrawerCollapsible,
      pendingChangesForRegeneration: {},
      featureNames,
      isUserMenuOpen: false,
      unreadNewsCount: 0
    }
  },
  computed: {
    isMobile () {
      return this.$vuetify.breakpoint.smAndDown
    },
    languageCode () {
      return this.$i18n.locale
    },
    currentPackage () {
      return this.company?.productType
    },
    showIsNew () {
      return !this.isPlatformMarkero
    },
    showRegeneration () {
      return this.isFeatureActive(this.featureNames.REGENERATION) &&
      Object.keys(this.pendingChangesForRegeneration).length > 0
    },
    inOnboarding () {
      return this.company?.state.stringValues.includes('ONBOARDING')
    },
    isInactive () {
      return this.company?.state.stringValues.includes('CREATED') ||
        this.company?.state.stringValues.includes('INACTIVE')
    },
    navItems () {
      if (!this.company || this.isInactive) {
        return []
      }

      if (this.inOnboarding) {
        return this.getActiveNavItems(ONBOARING_NAVIGATION)
      }

      return this.getActiveNavItems(BASIC_NAVIGATION)
    },
    navItemsBottimmo () {
      if (!this.company) {
        return []
      }
      return this.inOnboarding || this.isInactive
        ? this.getActiveNavItems(this.getNavItemsBottimmo().filter(({ onboarding }) => onboarding))
        : this.getActiveNavItems(this.getNavItemsBottimmo())
    }
  },
  watch: {
    showDrawer (val) {
      if (val) {
        productFruits.safeExec(() => {
          this.setupNewsList()
        })
      }
    }
  },
  mounted () {
    if (!this.isMobile) {
      productFruits.safeExec(() => {
        this.setupNewsList()
      })
    }
  },
  apollo: {
    company: {
      query: COMPANY_BASE,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      },
      skip () {
        return this.$auth.loading || !this.$auth.isAuthenticated
      }
    },
    pendingChangesForRegeneration: {
      query: PENDING_CHANGES_FOR_REGENERATION,
      variables () {
        return { companyId: this.company.id }
      },
      skip () {
        return !this.company || !this.role.isAuthorizedFor('settings-company:read')
      }
    }
  },
  methods: {
    setupNewsList () {
      const el = document.getElementById('news')
      try {
        // eslint-disable-next-line
        window.productFruits.api.announcementsV2.attachNewsWidgetToElement(el);

        // eslint-disable-next-line
        window.productFruits.api.announcementsV2.listen('newsfeed-unread-count-changed', (data) => {
          this.unreadNewsCount = data.count
        })
      } catch (e) {
        // eslint-disable-next-line
        console.error('Could not load news list', e)
      }
    },
    // The name should be changed, the nav is used for both domains
    getNavItemsBottimmo () {
      const navItems = []

      if (this.isPlatformMarkero) {
        const news = this.createNewsNavItem()
        navItems.push(news)
      }

      const support = this.createSupportNavItem()
      navItems.push(support)

      const legalNavItem = {
        label: 'navigation.labels.legal',
        trackingLabel: 'Legal',
        to: '/legal',
        onboarding: true,
        icon: 'mdi-file-document-outline',
        permission: 'legal:read'
      }
      navItems.push(legalNavItem)

      return navItems
    },
    createNewsNavItem () {
      return {
        label: 'navigation.labels.news',
        trackingLabel: 'News',
        icon: 'mdi-bell-outline',
        onboarding: false
      }
    },
    createSupportNavItem () {
      const support = {
        label: 'navigation.labels.support',
        trackingLabel: 'HelpCenter',
        icon: 'mdi-lifebuoy',
        onboarding: false,
        permission: 'support:read'
      }

      if (this.isPlatformMarkero) {
        support.href = (new URL(this.languageCode, process.env.VUE_APP_PRODUCT_FRUITS_HELP_CENTER)).toString()
        support.target = '_blank'
      } else {
        support.to = '/support'
      }

      return support
    },
    getActiveNavItems (items) {
      return items.filter(({ feature: featureSlug, checkConfigIsActive, permission }) => {
        const feature = featureSlug ? this.$features.feature(featureSlug) : null
        if (feature && (!feature.isActive || (checkConfigIsActive && !checkConfigIsActive(feature.config)))) {
          return false
        }

        if (permission && !this.role.isAuthorizedFor(permission)) {
          return false
        }

        return true
      })
    },
    logout () {
      this.trackClick('Logout')
      this.$auth.logout()
    },
    goHome () {
      this.$router.push({
        name: this.$auth.isAuthenticated ? 'dashboard' : 'home'
      }).catch((err) => {
        if (err.name !== 'NavigationDuplicated') {
          throw err
        }
      })
    },
    trackClick (label) {
      this.$tracking.event('Sidebar', this.$tracking.trackingEvents.CLICKED, label)
    }
  }
}
</script>

<style scoped>
.v-navigation-drawer--mini-variant .v-list-item > *:first-child{
  margin-right:0px !important;
}

.clickable {
  cursor: pointer;
}

.group-header{
  width: 100%;
}

.group-header :deep(.v-list-group__header) {
  padding: 0;
}

.group-item {
  min-height: 12px;
}

.navbar .v-list-item--active {
  border-radius: 10px;
}

.navbar .v-list-item--active:before {
 opacity: 0;
}

.navbar .v-list-item:before {
 border-radius: 10px;
}

.navbar{
  z-index: 10;
}

.user-item{
  transition: background-color .4s;
}

.unread-messages-count {
    width: 15px;
    height: 15px;
    position: absolute;
    top: -1px;
    background-color: red;
    color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    font-weight: bold;
    z-index: 9;
}

</style>
