<template>
  <div v-if="isActive" />
</template>

<script>
import { iubenda } from '@/lib/features'
import mixin from './mixin'

export default {
  mixins: [mixin],

  data: () => ({
    isActive: iubenda?.isActive,
    config: iubenda?.config
  }),

  mounted () {
    if (this.isActive) {
      this.init()
    }
  },

  methods: {
    init () {
      const consentSolutionConfig = this.getConsentSolutionConfig()
      const scriptContent = `
        var _iub = _iub || [];
        _iub.csConfiguration = ${JSON.stringify(consentSolutionConfig)};
      `

      this.loadScript({ scriptContent })

      ;[
        `https://cs.iubenda.com/autoblocking/${this.config.siteId}.js`,
        '//cdn.iubenda.com/cs/gpp/stub.js',
        '//cdn.iubenda.com/cs/iubenda_cs.js'
      ].forEach((src) => {
        this.loadScript({ src })
      })
    },

    getConsentSolutionConfig () {
      const lang = this.locale
      const siteId = this.config.siteId
      const cookiePolicyId = this.config.documentId[lang]

      return {
        ...this.config.consentSolutionConfig,
        banner: {
          // Logo can be overridden via config
          logo: require('@/../public/img/logo-large.png'),
          ...this.config.consentSolutionConfig.banner
        },
        lang,
        siteId,
        cookiePolicyId
      }
    },

    loadScript ({ src, scriptContent }) {
      const scriptElement = document.createElement('script')
      scriptElement.type = 'text/javascript'

      if (src) {
        scriptElement.src = src
        scriptElement.async = true
      } else {
        scriptElement.text = scriptContent
      }

      document.head.appendChild(scriptElement)
    }
  }
}
</script>
