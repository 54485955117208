import COMPANY_STATE from './CompanyState.gql'
import { getInstance } from '../auth/index'

const queryState = async (apollo, companyId) => {
  const { data: { company: { state: { stringValues } } } } = await apollo.defaultClient.query({
    query: COMPANY_STATE,
    variables: { id: companyId },
    fetchPolicy: 'no-cache'
  })
  return stringValues
}

const DEFAULT_ROUTES = {
  CREATED: 'inactive',
  ONBOARDING: {
    'ONBOARDING.USER': 'onboarding',
    'ONBOARDING.VALIDATION': 'onboardingValidation'
  },
  REGISTRATION: {
    'REGISTRATION.BILLING': 'payment',
    'REGISTRATION.ANAMNESIS': 'product-finder'
  },
  ACTIVE: 'dashboard',
  INACTIVE: 'inactive',
  SELF_ONBOARDING: 'registration'
}

const defaultRoute = ([state, ...substates]) => {
  const substate = substates[substates.length - 1]
  const route = DEFAULT_ROUTES[state]
  if (route === undefined) {
    return 'dashboard'
  }
  return route instanceof Object ? route[substate] : route
}

const state = (apollo, allowedStates) => async (to, from, next) => {
  const authService = getInstance()
  const state = await queryState(apollo, authService.user.companyId)
  if (allowedStates.some((allowedState) => state.includes(allowedState))) {
    return next()
  } else {
    return next({ name: defaultRoute(state) })
  }
}

export {
  state
}
